var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"f37q01b26tDytKOKArn3-"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

// FIXME: This is a temporary solution to ignore the enforce-module-boundaries rule that prevents circular dependencies
// eslint-disable-next-line @nx/enforce-module-boundaries
import { default as baseConfig } from '../../config/sentry.config';

Sentry.init({
  ...baseConfig,
});
